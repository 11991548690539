import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  AnalyticsSharedService,
  CustomSerializer,
  ENVIRONMENT_CONFIG,
  ErrorHandlerService,
  SentryService,
} from '@remedeelabs/surveys-common';
import { appRoutes } from './app.routes';
import { dateTimeProvider } from './shared/datetime.provider';
import { firestoreProvider } from './shared/firestore.provider';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideEffects(),
    provideStore(
      { router: routerReducer },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          // strictStateSerializability: true,
          // strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    environment.isDeveloperMode
      ? provideStoreDevtools({
          maxAge: 25,
          logOnly: !isDevMode(),
          name: 'NgRx Standalone App',
        })
      : [],
    provideRouterStore({ serializer: CustomSerializer }),
    provideRouter(
      appRoutes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    firestoreProvider(),
    ScreenTrackingService,
    UserTrackingService,
    AnalyticsSharedService,
    dateTimeProvider(),
    importProvidersFrom(MatSnackBarModule),
    provideAnimations(),
    SentryService,
    ErrorHandlerService,
    { provide: ErrorHandler, useExisting: ErrorHandlerService },
    { provide: ENVIRONMENT_CONFIG, useValue: environment },
  ],
};
