// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '@remedeelabs/surveys-common';

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDnZiEnTk4DONLS291mh5Zyt5bvTFAT1vo',
    authDomain: 'remedee-medical-dev.firebaseapp.com',
    projectId: 'remedee-medical-dev',
    storageBucket: 'remedee-medical-dev.appspot.com',
    messagingSenderId: '278614007999',
    appId: '1:278614007999:web:603208d1990b687a7e260a',
    measurementId: 'G-GKTVTXMCE9',
  },
  sentry: {
    dsn: 'https://1b5985bd8644352102bc38ed3c39ac5d@o4504690115870720.ingest.sentry.io/4505986355232768',
  },
  functionsBaseUrl:
    'https://europe-west1-remedee-medical-dev.cloudfunctions.net',
  version: '1.0.0-RC1',
  env: 'STAGING',
  isDeveloperMode: false,
};
