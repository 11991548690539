import { Routes } from '@angular/router';
import {
  ErrorPageComponent,
  ForbiddenPageComponent,
  NotFoundPageComponent,
  UnauthorizedPageComponent,
} from '@remedeelabs/surveys-common';

export const appRoutes: Routes = [
  {
    path: 'therapeutic-survey',
    loadChildren: () => import('./therapeutic-survey.routes'),
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey.routes'),
  },
  { path: 'error', component: ErrorPageComponent },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  { path: 'forbidden', component: ForbiddenPageComponent },
  // must be last!
  { path: '**', component: NotFoundPageComponent },
];
