import { importProvidersFrom } from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MtxDateFnsDatetimeModule } from '@ng-matero/extensions-date-fns-adapter';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';

import fr from 'date-fns/locale/fr';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export function dateTimeProvider() {
  return [
    importProvidersFrom(MatDateFnsModule),
    importProvidersFrom(MtxDateFnsDatetimeModule),
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'dd-MM-yyyy',
          monthInput: 'MMMM',
          yearInput: 'yyyy',
          timeInput: 'HH:mm',
          datetimeInput: 'dd-MM-yyyy HH:mm',
        },
        display: {
          dateInput: 'dd-MM-yyyy',
          monthInput: 'MMMM',
          yearInput: 'yyyy',
          timeInput: 'HH:mm',
          datetimeInput: 'dd-MM-yyyy HH:mm',
          monthYearLabel: 'yyyy MMMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM yyyy',
          popupHeaderDateLabel: 'MMM dd, E',
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: fr },
  ];
}
