import { importProvidersFrom } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { environment } from '../../environments/environment';

export function firestoreProvider() {
  return [
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => {
        const firestore = getFirestore();
        // if (!environment.production) {
        //   connectFirestoreEmulator(firestore, 'localhost', 8080);
        // }

        return firestore;
      }),
      provideAuth(() => {
        const auth = getAuth();
        /* if (environment.firestoreLocal) {
          connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: false,
          });
        } */
        return auth;
      })
    ),
    importProvidersFrom(
      provideAnalytics(() => {
        const analytics = getAnalytics(getApp());
        return analytics;
      })
    ),

    importProvidersFrom(
      provideFunctions(() => {
        const functions = getFunctions(
          getApp(),
          environment.production ? 'europe-west1' : 'us-central1'
        );
        return functions;
      })
    ),
  ];
}
